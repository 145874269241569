import React, { useState, useCallback } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import {
  useGoogleReCaptcha
} from 'react-google-recaptcha-v3';

import './App.css';

const firebaseConfig = {
  apiKey: "AIzaSyBD-4KE-qQsJDfNjf9wUN4uvWK421MiopA",
  authDomain: "mandalo-5d9a6.firebaseapp.com",
  projectId: "mandalo-5d9a6",
  storageBucket: "mandalo-5d9a6.appspot.com",
  messagingSenderId: "257936124325",
  appId: "1:257936124325:web:f81cc439b249c86982a04c",
  measurementId: "G-PKGSQ4K08F"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {
  const [email, setEmail] = useState('');
  const [alert, setAlert] = useState<string | null>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [language, setLanguage] = useState('es');

  const saveEmailToFirestore = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    if (token) {
      await addDoc(collection(db, "emails"), {
        email,
        timestamp: new Date()
      });
      setEmail('');
      setAlert('Gracias por tu interés, te notificaremos cuando estemos en vivo');
    }
    // Do whatever you want with the token
  }, [executeRecaptcha, email]);

  const toggleLanguage = (lang: 'en' | 'es') => {
    setLanguage(lang);
    // Here you would also update your translations
  };

  return (
    <div className="flex flex-col">
      {/* Updated Navigation Bar */}
      <nav className="bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center">
              <span className="block text-[#142d25] text-2xl font-bold">Mándalo</span>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => toggleLanguage('en')}
                className={`ml-4 px-2 py-1 ${language === 'en' ? 'border-b-2 border-gray-800' : ''}`}
              >
                En
              </button>
              <button
                onClick={() => toggleLanguage('es')}
                className={`ml-2 px-2 py-1 ${language === 'es' ? 'border-b-2 border-gray-800' : ''}`}
              >
                Es
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="h-screen overflow-hidden flex items-center justify-center">
        <div className="relative overflow-hidden">
          <div className="bg-white pt-10 pb-14 sm:pt-16 lg:overflow-hidden">
            <div className="mx-auto max-w-5xl lg:px-8">
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="mx-auto max-w-md px-4 text-center sm:max-w-2xl sm:px-6 lg:flex lg:items-center lg:px-0 lg:text-left">
                  <div className="lg:py-24">
                    <h1 className="mt-4 text-4xl font-bold tracking-tight text-black sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                      <span className="block text-black">
                        {language === 'es' ? 'dinero instantáneo' : 'instant money'}
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-gray-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                      {language === 'es' ? '¿Necesitas efectivo rápido? Obtén hasta $250 depositados al instante.' : 'Need quick cash? Get up to $250 deposited instantly.'}
                    </p>
                    <div className="mt-10 sm:mt-12">
                      <div className="sm:mx-auto sm:max-w-xl lg:mx-0">
                        {alert ? (
                          <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                            <p>{alert}</p>
                          </div>
                        ) : (
                          <div className="sm:flex">
                            <div className="min-w-0 flex-1">
                              <label htmlFor="email" className="sr-only">{language === 'es' ? 'Correo electrónico' : 'Email address'}</label>
                              <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={language === 'es' ? "correo electrónico" : "email address"}
                                className="block w-full rounded-md border-0 bg-gray-200 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400"
                              />
                                
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-3">
    
                                                                  <button
                                type="submit"
                                onClick={saveEmailToFirestore}
                                className="block w-full rounded-md bg-black py-3 px-4 font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2 focus:ring-offset-gray-900 hover:bg-gray-800 transition-colors duration-300"
                              >
                                {language === 'es' ? 'Únete' : 'Join'}
                              </button>

                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12 hidden lg:block">
                  <img
                    className="scale-70"
                    src="https://i.ibb.co/gtVJhCY/Screenshot-2024-10-10-at-3-26-56-PM.png"
                    alt=""
                    style={{ transform: 'scale(0.7)' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
